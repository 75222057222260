<template>
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="90" cy="90" r="70" fill="#E8EFFF" />
    <path
      d="M76.8569 103.408C76.8569 119.277 89.719 132.139 105.587 132.139C109.282 132.145 112.944 131.434 116.368 130.045C116.438 130.053 116.509 130.057 116.58 130.057H129.155C129.559 130.057 129.96 129.978 130.334 129.823C130.708 129.668 131.048 129.441 131.334 129.155C131.62 128.869 131.847 128.529 132.002 128.155C132.157 127.781 132.236 127.381 132.236 126.976V114.16C133.617 110.744 134.324 107.093 134.318 103.408C134.318 87.5401 121.456 74.678 105.587 74.678C89.719 74.678 76.8569 87.5401 76.8569 103.408Z"
      fill="#A2BDFF"
    />
    <path
      d="M117.088 83.0433C117.088 102.951 100.951 119.087 81.0439 119.087C76.4082 119.095 71.8151 118.202 67.5196 116.459C67.4312 116.47 67.3422 116.475 67.2532 116.475H51.4774C50.9697 116.475 50.4669 116.376 49.9978 116.181C49.5287 115.987 49.1025 115.703 48.7435 115.344C48.3845 114.985 48.0997 114.558 47.9056 114.089C47.7114 113.62 47.6116 113.117 47.6119 112.61V96.531C45.8796 92.2457 44.9927 87.6657 45 83.0436C45 63.136 61.136 47 81.0437 47C100.951 47 117.088 63.1357 117.088 83.0433Z"
      fill="#418DF9"
    />
    <path
      d="M87.3619 83.2669L95.6498 73.9436L90.0467 68L81.0584 77.4399L71.4864 69.515L66 75.5752L75.2218 83.6165L66.3502 93.0564L72.537 99L81.2918 89.094L90.8638 98.4173L96 91.4248L87.3619 83.2669Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IllustrationForbidden',
});
</script>

<style lang="scss"></style>
